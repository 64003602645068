<template>
  <div class="card">
    <div class="card-body">
      <div class="card-header card-header-divider">
        <div class="card-title">Список подписок компаний</div>
      </div>
      <div class="filter_block">
        <custom-input
            :delay="400"
            name="custom-text-filter"
            v-model="filterOptions.company_name"
            placeholder="Введите текст"
            :label="'Поиск по названию компании'"
        />
        <custom-input
            :delay="400"
            name="custom-text-filter"
            v-model="filterOptions.subscription_name"
            placeholder="Введите текст"
            :label="'Поиск по названию подписки'"
        />
        <div class="active_checkbox">
          <custom-checkbox
              name="custom-text-filter"
              v-model="filterOptions.active"
              :label="'Только активные'"
          />
        </div>
      </div>
      <div v-if='isLoaded'>
        <div v-if="shownData.length">
          <base-table
              :columns="subscriptionFields"
              :rows="shownData"
              @sort="(data) => {
                sortValue = data.sortValue;
                sortDirection = data.direction;
              }"
          />

          <table-pagination
              v-if="shownData.length"
              :key="updatePagination"
              :totalPages="1"
              :countOnPage="countOnPage"
              :total="totalResults ?? subscriptionRows.length"
              :count="countOnPage > shownData.length ? shownData.length : countOnPage"
              @changePage="changePage"
          />
        </div>
        <div v-else class="no_results">
          <p>Нет результатов</p>
        </div>
      </div>
      <div v-else class="table-preloader">
        <preloader/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTable from "@/components/Tables/BaseTable.vue";
import Preloader from "@/components/Technical/Preloader.vue";
import {onBeforeMount, ref, watch} from "@vue/runtime-core";
import {useStore} from "vuex";
import {computed, reactive} from "@vue/reactivity";
import router from "@/router";
import useCheckRights from "@/mixins/useCheckRights";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import sortData from "@/mixins/sortData";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";

export default {
  name: "SubscriptionList.vue",
  methods: {
    router() {
      return router
    }
  },
  components: {CustomCheckbox, CustomInput, TablePagination, Preloader, BaseTable},
  setup() {

    const countOnPage = ref(15);
    const store = useStore();
    const isLoaded = ref(false);
    const {getRight} = useCheckRights();
    const companySubscriptionList = computed(() => store.state.subscription.companySubscriptionList);
    const subscriptionRows = computed(() => companySubscriptionList.value.map((sub) => {
      return {
        id: sub.id,
        title: sub.title,
        owner_company: sub.owner_company,
        activated_at: sub.activated_at,
        expired_at: sub.expired_at,
        is_active: sub.is_active,
      }
    }));
    const subscriptionFields = [
      {
        field: "title",
        headerName: "Название подписки",
      },
      {
        field: "owner_company",
        headerName: "Компания",
      },
      {
        field: "activated_at",
        headerName: "Дата активации",
        sortable: true,
      },
      {
        field: "expired_at",
        headerName: "Истекает",
        sortable: true,
      },
      {
        field: "expire_button",
        headerName: "",
      },
    ];

    const filterOptions = reactive({
      subscription_name: "",
      company_name: "",
      active: false,
    });

    const pageDataStart = ref(0);
    const updatePagination = ref(false);
    const totalResults = ref();
    const sortValue = ref('');
    const sortDirection = ref('');

    const shownData = computed(() => {
      let data = subscriptionRows.value ?? [];

      if (data.length) {
        if (filterOptions.company_name) {
          data = data.filter((el) =>
              el.owner_company.toLowerCase().includes(filterOptions.company_name.toLowerCase()));
        }

        if (filterOptions.subscription_name) {
          data = data.filter((el) =>
              el.title.toLowerCase().includes(filterOptions.subscription_name.toLowerCase()));
        }

        if (filterOptions.active) {
          data = data.filter((el) => el.is_active);
        }

        if (sortValue.value) {
          data = sortData(data, sortValue.value, sortDirection.value, 'date');
        } else {
          data = sortData(data, 'activated_at', 'down', 'date');
        }

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }
      return data;
    });

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    onBeforeMount(() => {
      store.dispatch("profile/getProfile").then(() => {
        if (!getRight('superadmin')) {
          router.push('/');
        }
      });
      store.dispatch("subscription/getCompanySubscriptionList").then(() => {
            isLoaded.value = true;
          }
      ).catch(() => {
        isLoaded.value = true;
      });
    });

    watch(
        () => [
          filterOptions.subscription_name,
          filterOptions.company_name,
          filterOptions.active,
          sortDirection.value,
          sortValue.value,
          countOnPage.value
        ],
        () => {
          totalResults.value = 0;
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    return {
      subscriptionFields,
      shownData,
      subscriptionRows,
      changePage,
      countOnPage,
      isLoaded,
      filterOptions,
      totalResults,
      updatePagination,
      sortValue,
      sortDirection
    }
  }
}
</script>

<style scoped lang="scss">
.tools {
  display: flex;
  justify-content: flex-end;
}

.active_checkbox {
  padding-left: 10px;
}

.no_results {
  padding: 50px;
  text-align: center;
}

.card-body {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.card-header {
  margin: 0;
}

@media (max-width: 768px) {
  .active_checkbox {
    padding-left: 0;
    margin-top: 15px;
  }
}
</style>